/* section2 */
.section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 80px;
}

.section2_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
}

.data_tit {
  font-weight: 700;
  font-size: 50px;
  color: #fff;
  margin-top: 20px;
}

.data_wrap {
  width: 848px;
  display: flex;
  justify-content: space-between;
}

.data {
  color: #ffffff;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

.data div {
  font-weight: 700;
  font-size: 70px;
}

.data p {
  font-size: 25px;
}

@media (max-width: 1200px) {
  .section2 {
    height: auto;
    padding-bottom: 40px;
  }

  .section2_wrap {
    padding-top: 80px;
  }

  .data_tit {
    font-size: 25px;
    margin-top: 12px;
  }

  .data_wrap {
    width: 728px;
  }

  .data {
    width: 216px;
    margin-top: 40px;
  }

  .data div {
    font-weight: 700;
    font-size: 36px;
  }

  .data p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .section2_wrap {
    padding: 80px 20px 0px 20px;
  }

  .data_tit {
    font-size: 25px;
    margin-top: 12px;
  }

  .data_wrap {
    width: auto;
    flex-direction: column;
  }

  .data {
    width: 216px;
    margin: 20px 0px 20px 0px
  }

  .data_wrap > div:nth-child(1) {
    order: 1;
  }

  .data_wrap > div:nth-child(2) {
    order: 3;
  }

  .data_wrap > div:nth-child(3) {
    order: 2;
  }
}
