.slide_wrap {
  position: relative;
  width: 100%;
  height: 32px;
  display: flex;
  overflow: hidden;
  margin: 40px 0PX 0px 0px;
}

.section2_banner {
  position: absolute;
  width: 3000px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  white-space: nowrap;
}

.section2_banner img {
  height: 32px;
}

@media (max-width: 1200px) {
  .slide_wrap {
    height: 20px;
    margin: 20px 0PX 0px 0px;
  }

  .section2_banner {
    width: 2000px;
  }

  .section2_banner img {
    height: 20px;
  }
}
