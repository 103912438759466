.section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 0px;
}

.section_wrap {
  position: relative;
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.section_tit {
  font-weight: 700;
  font-size: 50px;
}

.lists {
  width: 920px;
  margin-top: 20px;
}

.list {
  /*box-shadow: 0px 0px 0px 0px !important;*/
  border-bottom: 1px solid #1B1B1B;
  padding: 20px 0px;
}

.list:last-child {
  border-bottom: 0px;
}

.list_header {
  font-weight: 700;
  font-size: 21px;
}

.list_body {
  font-size: 17px;
  padding-top: 12px;
}

.list:nth-child(3) .list_body {
  max-width:909px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .section {
    padding: 80px 40px;
  }

  .section_wrap {
    width: 688px;
    min-width: auto;
  }

  .section_tit {
    font-size: 25px;
  }

  .lists {
    width: auto;
    margin-top: 32px;
  }

  .list_header {
    font-size: 14px;
  }

  .list_body {
    font-size: 12px;
    padding-top: 6px;
  }
}

@media (max-width: 767px) {
  .section {
    padding: 80px 20px;
  }

  .section_wrap {
    /*max-width: 280px;*/
  }
}
