.section {
  height: 778px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1B1B1B;
}

.section_wrap {
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tit {
  font-weight: 700;
  font-size: 50px;
  color: #ffffff;
}

.process_image {
  width: 100%;
  margin-top: 60px;
}

.description_wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.description {
  width: 20%;
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  color: #ffffff;
  padding: 0px 23px;
  margin-top: 39px;
  max-width: 244px;
}

@media (max-width: 1200px) {
  .section {
    height: auto;
  }

  .section_wrap {
    width: auto;
    min-width: auto;
    padding: 80px 0px;
  }

  .tit {
    font-size: 25px;
  }

  .process_con_wrap {
    width: 264px;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .process_image {
    width: 126px;
    margin-top: 0px;
  }

  .description_wrap {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .description {
    width: 113px;
    font-size: 10px;
    text-align: start;
    color: #ffffff;
    padding: 0px;
    margin-top: 0;
  }
}

