/* reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; box-sizing: border-box; }
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section { display: block; }
body { line-height: 1;}
ol, ul, li { list-style: none; }
blockquote, q { quotes: none; }
blockquote:before, blockquote:after,å
q:before, q:after { content: ''; content: none; }
table { border-collapse: collapse; border-spacing: 0; }
a { text-decoration: none; color: inherit; }
input { box-sizing: border-box; padding: 0; }
textarea { padding: 10px; }
textarea:focus, input:focus {outline: none;}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}
select::-ms-expand {display: none;}
select {-o-appearance: none;-webkit-appearance: none;-moz-appearance: none;appearance: none; }
button{ background: 0; border: 0; cursor: pointer; }

#root {
  color: #1B1B1B;
  word-break: keep-all;
}

#root.en {
  font-family: 'Mulish', sans-serif;
  line-height: 1.3 !important;
}

#root.ko {
  font-family: 'Noto Sans KR', sans-serif;
  line-height: 1.5 !important;
}

.show_modal { overflow-y: hidden; height: 100%; }

/* Right -> left move slide banner css */
.original {
  animation: 40s linear 0s infinite normal forwards running rollingleft1;
}
.clone {
  animation: 40s linear 0s infinite normal none running rollingleft2;
}

@keyframes rollingleft1 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
  50.01%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(0);
  }
}

@keyframes rollingleft2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

/* Contact us submit button */
.form_submit_btn_valid {
  color: #1B1B1B !important;
  border: 2px solid #1B1B1B !important;
}

.form_submit_btn_valid:hover {
  color: #FFFFFF !important;
  background-color: #1B1B1B;
  transition-duration: 0.3s;
}

/* All page button css */
.common_Btn { position: relative; display: inline-block; font-weight: 700; font-size: 15px; color: #fff; line-height: 1; border: 4px solid #fff; border-radius: 500px; padding: 10px 36px; }
.common_Btn::after { content: ""; display: block; position: absolute; top: 50%; right: 10px; transform: translateY(-50%); width: 9px; height: 9px; border-radius: 50%; background: #fff; }

.common_Btn_bk { position: relative; display: inline-block; font-weight: 700; font-size: 15px; color: #1B1B1B; line-height: 1; border: 4px solid #1B1B1B; border-radius: 500px; padding: 10px 36px; }
.common_Btn_bk::after { content: ""; display: block; position: absolute; top: 50%; right: 10px; transform: translateY(-50%); width: 9px; height: 9px; border-radius: 50%; background: #1B1B1B; }


@media (max-width: 1200px) {
  .common_Btn { font-size: 10px; padding: 8px 26px; }
  .common_Btn::after { width: 5px; height: 5px; }

  .common_Btn_bk { font-size: 10px; padding: 8px 26px; }
  .common_Btn_bk::after { width: 5px; height: 5px; }
}

@media (max-width: 1200px) {
  #last-value-section {
    margin-bottom: 80px;
  }
}
