.modal_wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 21;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #1b1b1b60;
}

.popup {
  position: relative;
  width: 1000px;
  text-align: left;
  background: #1b1b1b;
  border-radius: 10px;
  vertical-align: middle;
  margin: 80px auto;
  padding: 40px;
}

.close_btn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.close_btn img {
  width: 32px;
}

.wrap_header {
  color: #FFFFFF;
  text-align: end;
  border-bottom: 1px solid #FFFFFF;
  margin: 28px 60px 0px 60px;
  padding-bottom: 60px;
}

.popup_tit {
  font-size: 50px;
}

.popup_des {
  font-size: 21px;
  margin-top: 10px;
}

.popup_des2 {
  font-size: 21px;
  line-height: 130%;
}

.sales_btn span {
  display: inline-block;
  color: #1b1b1b;
  background: #FFFFFF;
  border-radius: 500px;
  margin-top: 40px;
  padding: 12px 40px;
  cursor: pointer;
}

.body_image {
  margin: 60px;
}

.body_image img{
  width: 100%;
}

@media (max-width: 1200px) {
  .popup {
    width: 90%;
    padding: 30px;
  }

  .close_btn img {
    width: 22px;
  }

  .wrap_header {
    margin: 20px 30px 0px 30px;
    padding-bottom: 30px;
  }

  .popup_tit {
    font-size: 27px;
  }

  .popup_des {
    font-size: 14px;
  }

  .popup_des2 {
    font-size: 14px;
  }

  .sales_btn {
    font-size: 12px;
    margin-top: 20px;
    padding: 6px 20px;
  }

  .body_image {
    margin: 30px;
  }
}

@media (max-width: 767px) {
  .popup {
    width: 90%;
    padding: 20px;
  }

  .close_btn img {
    width: 22px;
  }

  .wrap_header {
    margin: 10px 10px 0px 10px;
    padding-bottom: 30px;
  }

  .popup_tit {
    font-size: 20px;
  }

  .popup_des {
    display: inline;
    font-size: 12px;
  }

  .popup_des2 {
    display: inline;
    font-size: 12px;
  }

  .sales_btn {
    padding: 6px 0px;
  }

  .sales_btn span {
    margin-top: 0px;
    padding: 6px 30px;
  }

  .body_image {
    margin: 30px 10px 0px 10px;
  }
}
