.section {
  height: 674px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_wrap {
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.main_tit {
  font-weight: 700;
  font-size: 50px;
  margin-top: 20px;
}

.sub_tit {
  font-size: 21px;
  margin-top: 32px;
  max-width: 1100px;
}

.sales span {
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #1B1B1B;
  border: 2px solid #1B1B1B;
  border-radius: 500px;
  margin-top: 41px;
  padding: 12px 16px;
  cursor: pointer;
}

.sales span:hover {
  color: #FFFFFF;
  background: #1B1B1B;
  transition-duration: 0.3s;
}

@media (max-width: 1200px) {
  .section {
    height: auto;
  }

  .section_wrap {
    width: auto;
    min-width: auto;
    padding: 80px 40px;
  }

  .main_tit {
    font-size: 25px;
    margin-top: 12px;
  }

  .sub_tit {
    width: 688px;
    font-size: 14px;
    margin-top: 24px;
    max-width: 516px;
  }

  .sales span {
    font-size: 15px;
    margin-top: 32px;
  }
}

@media (max-width: 767px) {
  .section_wrap {
    width: 100%;
    max-width: 280px;
    padding: 80px 0px;
  }

  .sub_tit {
    width: auto;
    font-size: 14px;
    margin-top: 24px;
    max-width:270px;
  }

}
