.section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_wrap {
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin-top: 160px;
  margin-bottom: 138px;
}

.section_left {
  max-width: 620px;
}

.tit {
  font-weight: 700;
  font-size: 50px;
  border-bottom: 1px solid #000000;
  margin-top: 20px;
  padding-bottom: 40px;
}

.des {
  font-size: 25px;
  margin-top: 40px;
  margin-right: 120px;
}

@media (max-width: 1200px) {
  .section_wrap {
    width: auto;
    flex-direction: column;
    min-width: auto;
    padding: 80px 40px 0px 40px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .section_left {
    width: 688px;
    max-width: inherit;
  }

  .tit {
    font-size: 25px;
    text-align: center;
    margin-top: 12px;
    padding-bottom: 20px;
  }

  .des {
    font-size: 14px;
    margin-top: 20px;
    margin-right: 0px
  }
}

@media (max-width: 767px) {
  .section_wrap {
    padding: 80px 20px 0px 20px;
  }

  .section_left {
    width: auto;
  }
}
