/* section1 */
.section1 {
  height: 786px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 56px;
}

.section_wrap {
  position: relative;
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 40px;
}

.section_contents_wrap {
  position: relative;
  width: 60%;
  margin-right: 7%;
}

.section_tit {
  font-weight: 700;
  font-size: 63px;
}

.section_con {
  font-weight: 300;
  font-size: 25px;
  margin-top: 60px;
  max-width: 645px;
}

.img1 {
 width: 33%;
}

@media (max-width: 1200px) {
  .section1 {
    height: auto;
  }

  .section_wrap {
    width: 100%;
    min-width: auto;
    justify-content: center;
    padding: 60px 40px 80px 40px;
  }

  .section_contents_wrap {
    width: 412px;
    margin-right: 80px;
  }

  .section_tit {
    width: 100%;
    font-weight: 700;
    font-size: 36px;
  }

  .section_con {
    font-size: 15px;
    margin-top: 40px;
  }

  .img1 {
    width: 200px;
  }
}

@media (max-width: 767px) {
  .section1 {
    height: auto;
  }

  .section_wrap {
    flex-direction: column;
    padding: 40px 20px 80px 20px;
  }

  .section_contents_wrap {
    width: 100%;
    /*max-width: 288px;*/
    margin-right: 0px;
    order: 2;
  }

  .section_tit {
    text-align: center;
  }

  .section_con {
    text-align: center;
    margin-top: 60px;
  }

  .img1 {
    width: 220px;
    order: 1;
    margin-bottom: 20px;
  }
}
