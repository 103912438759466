.header {
  background-color: #1B1B1B;
  display: flex;
  justify-content: center;
  color: #ffffff;
  padding: 20px;
  position: fixed;
  width: 100vw;
  height: 56px;
  z-index:20;
}

.contents {
  width: 66.6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 980px;
}

.nav {
  display: flex;
}

.nav_item {
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  margin-right: 57px;
}

.nav_item:nth-child(5) {
  margin-right: 0px;
}

.menuIcon, .mobile_language {
  display: none;
}

.language {
  position:relative;
}

.language img {
  padding: 15px 5px;
}

.language_dropdown {
  color: #ffffff;
  position:absolute;
  top: 50px;
  right: 0px;
  padding: 12px 0px 0px;
}

.language_dropdown ul {
  background: #1B1B1B;
  padding: 12px 20px;
  border-radius: 4px;
}

.language_dropdown li {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.language_dropdown li:not(:last-child):after {
  display: block;
  content: "";
  background: #ffffff;
  width: 100%;
  height: 1px;
  margin: 12px 0px;
}

@media screen and (max-width:1510px) {
  .nav_item {
    margin-right: 40px;
  }
}

@media screen and (max-width:1100px) {
  .contents {
    width: 100%;
    min-width: unset;
    /*position:relative;*/
  }
  .nav {
    display: none;
  }
  .nav.active {
    display: flex;
    flex-direction: column;
    background-color:#1B1B1B;
    position:absolute;
    left: 0px;
    top: 56px;
    width: 100vw;
    height: calc(100vh - 56px);
    padding: 30px 20px;
  }
  .nav_item {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 28px;
  }
  .mobile_language {
    display: flex;
    position:absolute;
    bottom: 80px;
  }

  .mobile_language span {
    font-size: 21px;
    font-weight: 700;
    margin-right: 12px;
  }
  .mobile_language_item {
    cursor:pointer
  }
  .language {
    display: none;
  }
  .menuIcon {
    display: block;
    cursor: pointer;
  }
}

/*@media screen and (max-height:650px) {*/
/*  .mobile_language {*/
/*    position:static;*/
/*  }*/
/*}*/
