.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.section_wrap {
  position: relative;
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
}

.contents {
  width: 1080px;
  font-size: 25px;
  color: #FFFFFF;
  text-align: center;
  margin-top: 40px;
}


@keyframes move-banner {
  0%{
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 1200px) {
  .section {
    height: auto;
  }

  .section_wrap {
    width: 100%;
    min-width: auto;
    max-width: 688px;
    padding: 60px 40px 80px 40px;
  }

  .contents {
    width: auto;
    font-size: 15px;
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .section_wrap {
    padding: 60px 20px 80px 20px;
  }

  .contents br {
    display: none;
  }
}
