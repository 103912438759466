.section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1B1B1B;
  color: #FFFFFF;
  padding: 160px 0px;
}

.section_wrap {
  position: relative;
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.investment {
  color: #1B1B1B;
  border: 3px solid #1B1B1B;
}

.investment::after {
  background: #1B1B1B;
}

.top {
  text-align: center;
  margin-top: 20px;
}

.title {
  font-weight: 700;
  font-size: 50px;
}

.description {
  width: 812px;
  font-size: 21px;
  margin: 32px auto 0px auto;
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  text-align: center;
  margin-top: 80px;
  /*padding: 0px 20px;*/
}

.icon {
  width: 80px;
  margin: 0px auto 8px auto;
}

.icon img {
  width: 100%;
}

.stage p {
  max-width: 300px;
}

.stage_tit {
  font-weight: 700;
  font-size: 25px;
}

.contents {
  font-size: 15px;
  margin-top: 9px;
}


@media (max-width: 1100px) {
  .section {
    padding: 80px 40px;
  }

  .section_wrap {
    width: auto;
    min-width: auto;
    padding: 80px 40px;
  }

  .top {
    width: auto;
    text-align: center;
    margin-top: 20px;
  }

  .title {
    font-size: 25px;
  }

  .description {
    width: auto;
    font-size: 15px;
    margin-top: 30px;
  }

  .bottom {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }

  .icon {
    margin: 80px auto 9px auto;
  }

  .stage p {
    width: 100%;
  }

  .stage_tit {
    font-size: 21px;
    margin-top: 0px;
  }

  .contents {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .section_wrap {
    padding: 80px 20px;
  }
}
