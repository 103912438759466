.section {
  padding: 160px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  width: 66.6%;
  min-width: 1100px;
  display: flex;
  justify-content: flex-start;
}

.title {
  max-width: 60%;
  font-weight:  700;
  font-size: 63px;
  margin-right: 100px;
}

.content {
  font-size: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*width: 60%;*/
}

.paragraph {
  display: flex;
  flex-direction: column;
}

.text {
  font-weight: 300;
}

.text.bold {
  font-weight: 700;
}


@media screen and (max-width: 1200px) {
  .section {
    padding: 80px 20px;
  }
  .title {
    font-size: 45px;
  }
  .wrapper {
    min-width: auto;
  }
  .content {
    font-size: 17px;
  }
}

@media screen and (max-width: 1100px) {
  .wrapper {
    flex-direction: column;
  }
  .title {
    font-size: 25px;
    margin-bottom:  48px;
    margin-right: 0px;
    max-width: 250px;
  }
  .paragraph {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
  }
}
