.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 160px 20px;
  width: 100%;

}
.wrapper {
  width: 66.6%;
  min-width: 1100px;
}

.text {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  /*font-size: 4rem;*/
  font-size: 63px;
  /*width: 40%;*/
  width:65%;
  max-width: 750px;
  margin-right: 50px;
}

.content {
  font-weight: 300;
  font-size: 25px;
  /*width:35%;*/
  max-width: 45%;
  margin-top: 10px;
}

.map {
  width: 100%;
  /*min-width: 1000px;*/
  height:648px;
  background-color: transparent;
  margin-top: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*border:1px solid black;*/
}

@media screen and (max-width: 1490px) {
  .section {
    padding: 160px 20px;
  }
  .title {
    max-width: 700px;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    width: 100%;
    min-width: auto;
  }
  .text {
    width: 66.6%;
    margin: 0 auto;
  }
  .title {
    font-size: 45px;
  }
  .content {
    font-size: 17px;
  }
  .map {
    width: 1000px;
    margin: 95px auto 0px;
  }
}


@media screen and (max-width: 1100px) {
  .wrapper {
    width: 688px;
  }

  .section {
    padding: 120px 20px 80px;
  }

  .text {
    width: 100%;
    flex-direction: column;
    align-items: end;
  }
  .title {
    font-size: 36px;
    margin-bottom: 60px;
    margin-right: 0px;
    width: 100%;
  }
  .content {
    font-size: 15px;
    width: 90%;
    text-align: end;
    max-width:unset;
    margin-top: 0px;
  }
  .map {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding: 110px 20px 80px;
  }
  .wrapper {
    width: 100%;
  }
}

