.section {
  display: flex;
  justify-content: center;
  color: #ffffff;
  min-height: 1377px;
  background-image: url('../../assets/images/common/bg-abstract-big.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 0px;
  width: 100%;

}
.wrapper {
  width: 66.6%;
  min-width: 1100px;
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 50px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 186px;
}

.item.item_title {
  font-weight: 700;
  font-size: 36px;
  margin-top: 0px;
}

.item {
  font-weight: 400;
  font-size: 25px;
  margin-top: 20px;
}

@media screen and (max-width: 1490px) {
  .section {
    padding: 140px 20px;
    min-width: auto;
  }
}

@media screen and (max-width: 1200px) {
  .section {
    min-height: 1260px;
  }

  .wrapper {
    width: 100%;
    max-width: 688px;
    min-width: auto;
  }

  .content {
    margin-right: 0px;
    padding-left: 50px;
  }
  .title {
    font-size: 21px;
  }
  .item.item_title {
    font-size: 25px;
  }
  .item {
    font-size: 17px;
  }
}

@media screen and (max-width: 1100px) {
  .section {
    background: #1B1B1B;
    min-height: unset;
  }
  .wrapper {
    flex-direction: column;
    justify-content: unset;
  }
  .title {
    margin-bottom: 60px;
  }

  .content {
    padding-left: 0px;
  }
  .list {
    margin-bottom: 80px;
  }
  .list:nth-child(3) {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding: 80px 20px;
  }
    .wrapper {
      width: 100%;
    }

  .title {
    font-size: 21px;
  }
  .item.item_title {
    font-size: 25px;
  }
  .item {
    font-size: 17px;
  }
}

