.page {
  padding: 160px 20px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code {
  font-weight: 700;
  font-size: 101px;
  line-height:  130%;
  margin-bottom: 28px;
}

.title {
  font-weight: 700;
  font-size: 25px;
  line-height: 130%;
  margin-bottom: 10px;
}

.content {
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  margin-bottom: 68px;
  text-align: center;
}

.button {
  background-color: #1b1b1b;
  color: #ffffff;
  border-radius: 500px;
  padding: 12px 45px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  cursor: pointer;
}
