.section {
  height: 749px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 56px;
}

.section_wrap {
  position: relative;
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 118px;
}

.getInTouch_left {
  width: 50%;
}

.title {
  font-weight: 700;
  font-size: 50px;
}

.getInTouch_right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.getInTouch_form_wrap {
  width: 100%;
  padding-left: 100px;
}

.inquiry {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.inquiry li {
  width: 48%;
  margin-top: 32px;
}

.inquiry li p {
  font-weight: 700;
  font-size: 17px;
}

.email {
  font-size: 15px;
  margin-top: 6px;
}

@media (max-width: 1200px) {
  .section {
    height: auto;
  }

  .section_wrap {
    width: 100%;
    min-width: auto;
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }

  .getInTouch_left {
    width: 100%;
    background-color: #1B1B1B;
    color: #FFFFFF;
    padding: 64px 0px 80px 0px;
  }

  .title {
    font-size: 36px;
    text-align: center;
  }

  .getInTouch_right {
    width: 100%;
  }

  .getInTouch_form_wrap {
    width: 540px;
    padding: 80px 0px;
  }

  .inquiry {
    max-width: 688px;
    margin: 12px auto 0px auto;
  }

  .inquiry li {
    width: 48%;
    text-align: center;
    margin-top: 20px;
  }

  .email {
    font-size: 15px;
    margin-top: 6px;
  }
}

@media (max-width: 767px) {
  .getInTouch_form_wrap {
    width: 280px;
  }

  .inquiry li {
    width: 100%;
  }
}
