.section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 0px;
}

.section_wrap {
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding: 0px 20px;
}

.tit {
  font-size: 63px;
  text-align: left;
}

.tit span {
  font-weight: 700;
}

@media (max-width: 1200px) {
  .section {
    height: auto;
    padding: 120px 0px 80px 0px;
  }

  .section_wrap {
    width: auto;
    align-items: flex-start;
    min-width: auto;
    padding: 0px 40px 0px 40px;
    max-width: 688px;
  }

  .tit {
    font-size: 36px;
  }

  .tit br {
    display: none;
  }
}
