.section {
  height: 1411px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_wrap {
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
}

.main_tit {
  font-weight: 700;
  font-size: 50px;
  margin-top: 20px;
}

.project {
  width: 100%;
  display: flex;
  /*justify-content: space-between;*/
  justify-content: center;
  align-items: center;
  margin: 32px 0px 0px 0px;
  padding: 0px 20px;
}

.list_wrap {
  position: relative;
  width: 236px;
  height: 236px;
  padding: 3px;
  cursor: pointer;
}

.list {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  padding: 3px;
}

.list_bg {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(27, 27, 27, 0.8);
  border-radius: 6px;
  padding: 16px;
}

.list_bg_tit {
  font-weight: 600;
  margin-top: 5px;
}

.list_bg_bot {
  text-align: end;
}

.project img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 6px;
}

.description {
  display: flex;
  flex-wrap: wrap;
  margin-top: 120px;
}

.description_list {
  width: 25%;
  text-align: center;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description_list img {
  width: 48px;
  display: block;
  margin: 0 auto 24px auto;
}

.title {
  font-weight: 700;
  font-size: 17px;
}

.contents {
  font-size: 15px;
  margin-top: 12px;
  padding: 0px 5%;
  max-width:265px;
}
.description_list:nth-child(1) .contents {
  max-width:260px;
}

.button {
  width: 100%;
}

.button span {
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  color: #1B1B1B;
  border: 2px solid #1B1B1B;
  border-radius: 500px;
  padding: 12px 0px;
  cursor: pointer;
}

.button span:hover {
  color: #FFFFFF;
  background: #1B1B1B;
  transition-duration: 0.3s;
}

@media (max-width: 1200px) {
  .section {
    height: auto;
  }

  .section_wrap {
    width: auto;
    min-width: auto;
    padding: 80px 40px;
  }

  .main_tit {
    font-size: 25px;
    margin-top: 12px;
  }

  .project {
    width: 688px;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    align-items: flex-start;
    margin: 32px 0px 0px 0px;
    padding: 0px 56px;
  }

  .project img {
    width: 230px;
    height: 230px;
  }

  .description {
    max-width: 688px;
    margin-top: 60px;
  }

  .description_list {
    margin-bottom: 60px;
  }

  .description_list img {
    width: 32px;
    margin: 0 auto 8px auto;
  }

  .title {
    font-size: 15px;
  }

  .contents {
    font-size: 10px;
    margin-top: 10px;
    padding: 0px 10px;
  }

  .button span {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .section_wrap {
    width: 100%;
    padding: 80px 20px;
  }

  .list_wrap {
    position: relative;
    width: 100%;
    height: auto;
  }

  .list {
    padding: 6px;
  }

  .project {
    width: 100%;
    max-width: 450px;
    margin: 27px 0px 0px 0px;
    padding: 0px;
  }

  .project img {
    width: 100%;
    height: auto;
    padding: 3px;
  }

  .description {
    /*max-width: 266px;*/
  }

  .description_list {
    width: 50%;
    margin-bottom: 40px;
  }

  .contents {
    padding: 0px 5px;
    max-width: inherit;
  }
}

