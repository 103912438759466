.section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
}

.section_wrap {
  position: relative;
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.section_tit {
  font-weight: 700;
  font-size: 50px;
}

.form_wrap {
  width: 640px;
  padding-top: 32px;
}

@media (max-width: 1200px) {
  .section {
    height: auto;
    padding: 0px;
  }

  .section_wrap {
    width: auto;
    min-width: auto;
    padding: 80px 0px;
  }

  .section_tit {
    font-weight: 700;
    font-size: 25px;
  }

  .form_wrap {
    width: 540px;
  }
}

@media (max-width: 767px) {
  .section {
    height: auto;
  }

  .section_wrap {
    width: 100%;
    padding: 80px 20px;
    /*max-width: 280px;*/
  }

  .form_wrap {
    width: 100%;
  }
}
