.form_main {
  width: 100%;
  margin: 0px auto;
}

.form_line {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.form_subtit {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 6px;
}

.form_subtit span {
  color: #FF0000;
}

.form_first_name, .form_last_name, .form_company, .form_country {
  width: 49%;
}

.form_full_div {
  width: 100%;
}

.form_input, .form_inquiry {
  width: 100%;
  height: 34px;
  font-family: 'Poppins', sans-serif;
  background: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding: 0px 10px;
}

.error {
  font-size: 10px;
  color: #E90000;
  margin-top: 4px;
}

.form_textarea {
  width: 100%;
  max-width: 642px;
  font-family: 'Poppins', sans-serif;
  background: #F9F9F9;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4px;
}

.form_submit_btn {
  width: 100%;
  height: 39px;
  font-weight: 700;
  font-size: 15px;
  color: #CDCDCD;
  border: 2px solid #CDCDCD;
  border-radius: 500px;
  margin-top: 16px;
}

/* inquiry custom */
.list {
  position: relative;
  cursor: pointer;
}

.list_header {
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding: 0px 10px;
}

.list_header svg {
  width: 15px;
}

.list_body {
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  background: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-top: 0px;
  border-radius: 4px;
  padding: 10px;
}

.list_body li {
  font-size: 14px;
  color: #D9D9D9;
  padding: 5px 0px;
}

.list_body li:hover {
  color: #1B1B1B;
}

/* Response Popup */
.popup_wrap {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupBg {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  width: 100%;
  height: 100%;
  background-color: #1b1b1b60;
}

.popup {
  position: relative;
  color: #1B1B1B;
  background: #FFFFFF;
  border-radius: 10px;
  text-align: center;
  padding: 40px;
}

.title {
  font-size: 36px;
  font-weight: 700;
}

.content1 {
  font-size: 17px;
  margin-top: 10px;
}

.content {
  font-size: 17px;
}

.button {
  font-weight: 700;
  color: #D9D9D9;
  border: 2px solid #D9D9D9;
  border-radius: 500px;
  margin-top: 40px;
  padding: 12px 16px;
}

.button:hover {
  color: #1B1B1B;
  border: 2px solid #1B1B1B;
  transition-duration: .3s;
}

@media (max-width: 767px) {
  .popup {
    width: 90%;
    padding: 20px;
  }

  .title {
    font-size: 25px;
  }

  .content1 {
    font-size: 12px;
    margin-top: 5px;
  }

  .content {
    font-size: 12px;
  }

  .button {
    font-size: 12px;
    margin-top: 20px;
    padding: 6px 8px;
  }
}
