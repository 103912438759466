.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  background: #1B1B1B;
}

.footer_wrap {
  width: 66.66%;
  min-width: 1100px;
  padding-top: 75px;
}

.footer_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 64px;
  border-bottom: 1px solid #FFFFFF;
}

.logo {
  width: 207px;
}

.description {
  font-size: 15px;
  margin-top: 20px;
}

.email {
  font-size: 15px;
  margin-top: 212px;
}

.nav_item:first-child {
  margin-top: 0px;
}

.nav_item {
 font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  margin-top: 24px;
}

.nav_item:nth-child(1) {
  cursor: unset;
}

.footer_bot {
  font-size: 12px;
  text-align: end;
  padding: 32px 0px;
}

@media (max-width: 1200px) {
  .footer_wrap {
    width: 100%;
    min-width: auto;
    padding: 75px 40px;
  }

  .footer_top {
    flex-direction: column;
    padding-bottom: 60px;
  }

  .email {
    margin-top: 10px;
  }

  .nav {
    margin-top: 60px;
  }

  .nav_item {
    cursor: pointer;
    margin-top: 24px;
  }

  .footer_bot {
    font-size: 12px;
    text-align: end;
    padding: 32px 0px 0px 0px;
  }
}

@media (max-width: 1200px) {
  .footer_wrap {
    padding: 75px 20px;
  }
}
