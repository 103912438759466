.map_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map_img {
  position:absolute;
  top:0;
  left:0;
  width: 100%;
  /*opacity: 0;*/
}

.map_img img{
  width: 100%;
  opacity: 0;
}

.map_img.first img {
  opacity: 1;

}

.mouse {
  color: #1B1B1B;
  z-index: 20;
  position:absolute;
}
