.section {
  height: 792px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_wrap {
  position: relative;
  width: 66.66%;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top {
  max-width: 812px;
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top.korean {
  max-width: 900px;
}


.title {
  font-weight: 700;
  font-size: 50px;
}

.description {
  font-size: 21px;
  margin-top: 32px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  margin-top: 80px;
}

.stage p {
  width: 300px;
}

.stage_tit {
  font-weight: 700;
  font-size: 25px;
}

.contents {
  font-size: 15px;
  margin-top: 9px;
}

@media (max-width: 1100px) {
  .section {
    height: auto;
  }

  .section_wrap {
    width: auto;
    min-width: auto;
    padding: 80px 20px;
  }

  .top {
    width: auto;
    text-align: center;
    margin-top: 12px;
  }

  .title {
    font-size: 25px;
    max-width:420px;
  }

  .top.korean .description {
    max-width: unset;
  }

  .description {
    font-size: 15px;
    margin-top: 12px;
    max-width: 600px;
  }

  .bottom {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  .stage p {
    width: 100%;
  }

  .stage_tit {
    font-size: 21px;
    margin-top: 40px;
  }

  .contents {
    font-size: 14px;
  }
}

@media (max-width: 1100px) {

}
