.section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 0px 80px 0px;
}

.section_wrap {
  position: relative;
  width: 66.66%;
  min-width: 1100px;
  height: 100%;
  padding: 0px 32px;
}

.title {
  font-size: 63px;
  /*font-weight: 600;*/
  padding-bottom: 60px;
  width: 90%;
}

.bold {
  font-weight: 700;
}

.title span {
  font-weight: 700;
  display: block;
}

.description {
  font-weight: 300;
  font-size: 25px;
  text-align: end;
  margin-top: 60px;
}

@media (max-width: 1750px) {
  .title {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .section {
    height: auto;
  }

  .section_wrap {
    width: 100%;
    max-width: 688px;
    min-width: auto;
    padding: 0px 40px 0px 40px;
  }

  .title {
    font-size: 36px;
    padding-bottom: 0px;
    margin-top: 0px;
  }

  .title span {
    display: inline;
  }

  .description {
    font-size: 15px;
    text-align: end;
    position: static;
  }
}

@media (max-width: 767px) {
  .section_wrap {
    padding: 0px 20px 0px 20px;
  }
}

@media (max-width: 500px) {
  .title p {
    display: inline;
  }
}
